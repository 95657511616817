<template>
    <b-modal
        id="modalHomeBoxCreateEdit"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @show="openModalHandler"
        @hidden="$emit('hidden')"
    >
        <template slot="modal-title">
            {{ modalTitle }}
        </template>
        <div class="custom-modal-text text-left">
            <div>
                <div class="form-group">
                    <label for="title">Title</label>
                    <input
                        id="title"
                        v-model="data.title"
                        :class="{ 'is-invalid': $v.data.title.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Enter Title"
                    />
                </div>
                <div class="form-group">
                    <label for="title">Text</label>
                    <input
                        id="title"
                        v-model="data.text"
                        :class="{ 'is-invalid': $v.data.text.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Enter In App Text"
                    />
                </div>
                <div class="form-group">
                    <label for="title">Route Name</label>
                    <b-form-select
                        v-model="data.route_name"
                        :options="routeNames"
                        text-field="text"
                    />
                </div>
                <div class="form-group">
                    <label for="title">Link</label>
                    <input
                        id="title"
                        v-model="data.url"
                        type="text"
                        class="form-control"
                        placeholder="Enter Redirect External Link"
                    />
                </div>
                <div class="form-group">
                    <image-input
                        v-model="data.icon"
                        path-to-upload="/home-boxes/image"
                        path-to-get-image="/images/home-boxes"
                        label="Icon"
                        :error="$v.data.icon.$error"
                    />
                </div>
                <b-form-checkbox v-model="data.full_width" switch class="my-2">
                    Full Width
                </b-form-checkbox>
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="submitHomeBoxDataHandler"
                >
                    {{ buttonText }}
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import 'flatpickr/dist/flatpickr.css';
import { required } from 'vuelidate/lib/validators';

import ImageInput from '@components/elements/ImageInput';

export default {
    components: {
        ImageInput
    },

    props: {
        updatingHomeBox: {
            type: Boolean,
            required: false,
            default: true
        },
        homeBox: {
            type: Object,
            required: false,
            default: null
        },
        modalTitle: {
            type: String,
            required: false,
            default: 'Add Home Box Information'
        },
        buttonText: {
            type: String,
            required: false,
            default: 'Save'
        }
    },

    data() {
        const defaultData = {
            title: '',
            text: '',
            route_name: '',
            url: '',
            icon: null,
            full_width: false
        };

        return {
            data: {
                ...defaultData
            },
            defaultData,
            routeNames: [
                { value: null, text: 'None' },
                { value: 'ProductInformation', text: 'ProductInformation' },
                { value: 'SetUpTraining', text: 'SetUpTraining' },
                {
                    value: 'NurseProductInformation',
                    text: 'NurseProductInformation'
                },
                { value: 'Categories', text: 'MedicalCategories' }
            ]
        };
    },

    validations: {
        data: {
            title: {
                required
            },
            text: {
                required
            },
            icon: {
                required
            }
        }
    },

    methods: {
        hideModal() {
            this.$bvModal.hide('modalHomeBoxCreateEdit');

            this.data = {
                ...this.defaultData
            };
        },

        async openModalHandler() {
            await this.$nextTick();

            this.data = {
                ...this.homeBox
            };
        },

        submitHomeBoxDataHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.$emit(
                this.updatingHomeBox ? 'update-home-box' : 'create-home-box',
                this.data
            );

            this.$v.$reset();
            this.hideModal();
        }
    }
};
</script>

<style lang="scss" scoped>
.image-preview {
    width: 390px;

    img {
        width: 100%;
        aspect-ratio: 1.3;
        object-fit: cover;
    }
}
</style>
