<script>
import { mapActions } from 'vuex';
import config from '@config';
import TableView from '@components/elements/TableView';
import ConfirmModal from '@components/elements/ConfirmModal';
import ModalHomeBoxCreateEdit from '@components/home-boxes/ModalHomeBoxCreateEdit';

export default {
    page: {
        title: 'Home Boxes'
    },

    components: {
        ModalHomeBoxCreateEdit,
        ConfirmModal
    },

    extends: TableView,

    data() {
        return {
            pageTitle: 'Home Boxes',
            homeBoxToUpdate: null,
            homeBoxToDelete: null,
            modalTitle: ''
        };
    },

    computed: {
        additionalComponents() {
            return [
                {
                    name: 'modal-home-box-create-edit',
                    props: {
                        modalTitle: this.homeBoxToUpdate
                            ? 'Edit Home Box'
                            : 'Add Home Box',
                        buttonText: this.homeBoxToUpdate ? 'Update' : 'Add',
                        updatingHomeBox: !!this.homeBoxToUpdate,
                        homeBox: this.homeBoxToUpdate
                    },
                    events: {
                        hidden: this.hideModal,
                        'create-home-box': this.createHomeBoxHandler,
                        'update-home-box': this.updateHomeBoxHandler
                    }
                },
                {
                    name: 'confirm-modal',
                    props: {
                        title: this.modalTitle
                    },
                    events: {
                        agree: this.deleteHomeBoxHandler,
                        disagree: this.hideModal
                    }
                }
            ];
        },

        labels() {
            return [
                { value: 'icon', width: '200px' },
                { value: 'title', width: '180px' },
                { value: 'text', width: '180px' },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    width: '200px',
                    type: 'date'
                },
                {
                    value: 'updated_at',
                    filter: 'formatDate',
                    width: '200px',
                    type: 'date'
                }
            ];
        },

        actions() {
            const actions = [
                {
                    icon: 'fas fa-pen mr-1',
                    method: 'showEditModal'
                }
            ];

            return actions;
        },

        options() {
            return {
                searchInput: false,
                clickableRows: false,
                sort: false,
                filters: false
            };
        }
    },

    methods: {
        ...mapActions({
            getItems: 'homeBoxes/index',
            createHomeBox: 'homeBoxes/store',
            updateHomeBox: 'homeBoxes/update',
            deleteHomeBox: 'homeBoxes/destroy'
        }),

        async createHomeBoxHandler(data) {
            try {
                const response = await this.createHomeBox(data);

                if (!this.items.map(item => item.id).includes(response.id)) {
                    this.items = [response, ...this.items];
                }

                this.$bvToast.toast('Home box has been added.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async updateHomeBoxHandler(data) {
            try {
                const updatedHomeBox = await this.updateHomeBox(data);

                const index = this.items.findIndex(
                    item => item.id === updatedHomeBox.id
                );

                this.items[index] = updatedHomeBox;

                this.$bvToast.toast('Home box has been updated.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async deleteHomeBoxHandler() {
            try {
                await this.deleteHomeBox(this.homeBoxToDelete);

                this.items = this.items.filter(
                    item => item.id !== this.homeBoxToDelete.id
                );

                this.$bvToast.toast('Home box has been deleted.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        showEditModal(homeBox) {
            this.homeBoxToUpdate = homeBox;

            this.$bvModal.show('modalHomeBoxCreateEdit');
        },

        showDeleteModal(homeBox) {
            this.homeBoxToDelete = homeBox;
            this.modalTitle =
                'Please confirm that you want to delete this home box';

            this.$bvModal.show('confirmModal');
        },

        hideModal() {
            this.homeBoxToUpdate = null;
            this.homeBoxToDelete = null;
        },

        onAddButtonClick() {
            this.$bvModal.show('modalHomeBoxCreateEdit');
        },

        getImagePath(image) {
            return `${config.mediaUrl}/images/home-boxes/${image.date}/${image.id}.${image.extension}`;
        }
    }
};
</script>
